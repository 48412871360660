export interface IUserContext {
    [key: string]: string;
}

export class UserContextHandler implements IUserContextHandler {
    private static invalidCharacters = /[^a-z0-9_]/gi;

    private context: IUserContext;

    constructor() {
        this.context = {};
    }

    public initializeContext(context: IUserContext): IUserContextHandler {
        this.context = this.replaceInvalidCharactersInContext(context);
        return this;
    }

    public setContext(key: string, value: string): IUserContextHandler {
        this.context[this.replaceInvalidCharacters(key)] = value;
        return this;
    }

    public getContext(): IUserContext {
        return this.context;
    }

    private replaceInvalidCharactersInContext(context: IUserContext): IUserContext {
        const validContext: IUserContext = {};
        Object.keys(context)
            .map((key) => {
                return {
                    key: this.replaceInvalidCharacters(key),
                    value: context[key],
                };
            })
            .filter((contextValue) => contextValue.key !== '')
            .forEach((contextValue) => (validContext[contextValue.key] = contextValue.value));
        return validContext;
    }

    private replaceInvalidCharacters(key: string): string {
        if (typeof key === 'string') {
            return key.replace(UserContextHandler.invalidCharacters, '_');
        } else {
            console.error(`The user context key '{key}' is not a string.`);
            return '';
        }
    }
}

export interface IUserContextHandler {
    initializeContext(context: IUserContext): IUserContextHandler;
    setContext(key: string, value: string): IUserContextHandler;
    getContext(): IUserContext;
}

export const handler = new UserContextHandler();
